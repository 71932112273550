import React, { useEffect } from 'react'
import { Link } from 'gatsby';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Layout } from '../../components/layout'
import { Section, Subsection, MiddleSectionPadding, defaultPadding } from '../../components/section';
import { langPrefix, passLanguage, useLanguage } from '../../components/language-context'
import { BoldText, CTAButton, OptionImage, Row, NoGutterRow, Row3Col1, Row3Col2, RowColHalf, RowGraphicWrapper, SectionIds, SectionSubheading, TextParagraph, RowCol, GridCol, RatioRowCol } from '../../components/platform-common-components'
import { JumboImage } from '../../components/solution-customized-components'
import crowdAboveImage from '../../images/product-services/operations/crowd-above@2x.png'
import inOutSilo3Image from '../../images/product-services/operations/in-out-silo-3@2x.png'
import storeFrontImage from '../../images/product-services/operations/store-front@2x.png'
import storeFrontAngleImage from '../../images/product-services/operations/store-front-angle@2x.png';
import checkInOutHeader from '../../images/product-services/operations/in-out-header@2x.png';
import peopleCounterHeader from '../../images/product-services/operations/people-counter-header@2x.png';
import noiseSensorHeader from '../../images/product-services/operations/noise-sensor-header@2x.png';
import newBadgeImage from '../../images/product-services/operations/new-badge.svg';
import euracamCounterImage from '../../images/product-services/operations/eurecam-counter@3x.png';
import cubeIcon from '../../images/product-services/operations/icons/cube.svg';
import chartIcon from '../../images/product-services/operations/icons/chart.svg';
import treeIcon from '../../images/product-services/operations/icons/tree.svg';
import noiseSensorTrendsImg from '../../images/product-services/operations/noise-sensor-trends.png';
import { smoothScrollTo } from '../../components/offering-common-components';
import { media } from '../../styles/media-query-template';
import { GACategories } from '../../services/analytics-service';
import { goToRequestForm } from '../../components/request-form';

const LinkButton = styled(Link)`
  display: flex;
  font-size: 1rem;
  background-color: #FFFFFF;
  border: 1px solid #1f2d54;
  padding: 8px 32px;
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  flex-grow: 0;
  align-self: center;
  color: #000000;
`

const CounterImage = styled.img`
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  ${media.large`
    height: 270px;
    width: auto;
  `}
`

const Badge = styled.img`
  position: relative;
  bottom: 20px;
  margin-bottom: -20px; 
  left: 0;
  width: 40px;
  ${media.large`
    bottom: 40px;
    margin-bottom: -40px; 
    left: 35px;
    width: 80px;
  `}
`

const CounterBulletList = styled(RowColHalf)`
  margin-top: 30px;
  ${media.large`
    margin-top: 0;
  `}
`

const Icon = styled.img`
  width: 35px;
`

const CustomButton = styled(CTAButton)`
  margin-top: 50px;
  width: 100%;

  ${media.large`
    width: auto;
  `}
`

const IconContainer = styled(RatioRowCol)`
  align-self: flex-start;
  align-items: center;
  margin: 15px 0;
  ${media.large`
    margin-bottom: 0;
  `}
`

const HeaderLinkContainer = styled(NoGutterRow)`
  display: none;
  ${media.large`
    display: flex;
  `}
`

const HeaderImageColContainer = styled(RowColHalf)`
  flex: 1;
  ${media.large`
    flex: 0.5;
  `}
`

const ClickableJumboImage = styled(JumboImage)`
  &:hover{
    cursor: pointer;
  }
`

const HeaderRow = styled(NoGutterRow)`
  flex-wrap: nowrap;
`

const PaddedOptionImage = styled(OptionImage)`
  padding-top: 15px;
  ${media.large`
    padding-top: 0;
  `}
`

const NoiseTrendsImage = styled.img`
  max-width: 90%;
  height: auto;
  min-height: 0;
  align-self: flex-start;
`;

const OperationsPageBase = useLanguage(({ location, lang, msg, scrollTo }) => {
  const url = location ? location.pathname : '';
  const checkInAndOutLink = langPrefix(lang, `/product-services/check-in-and-out`);
  const peopleCounterLink = langPrefix(lang, `/product-services/people-counter`);
  const noiseSensorLink = langPrefix(lang, `/product-services/noise-sensor`);

  useEffect(() => {
    if (!scrollTo) return;
    smoothScrollTo(scrollTo);
  });

  const goToAnalyze = () => {
    navigate(langPrefix(lang, `/platform/insights`));
  };

  const goToCounter = () => {
    navigate(peopleCounterLink);
  };

  const goToCheckInAndOut = () => {
    navigate(checkInAndOutLink);
  };

  return (
    <Layout location={location}>
      <HeaderRow>
        <HeaderImageColContainer>
          <ClickableJumboImage src={noiseSensorHeader} onClick={goToCounter} />
        </HeaderImageColContainer>
        <HeaderImageColContainer>
          <ClickableJumboImage src={peopleCounterHeader} onClick={goToCounter} />
        </HeaderImageColContainer>
        <HeaderImageColContainer>
          <ClickableJumboImage src={checkInOutHeader} onClick={goToCheckInAndOut} />
        </HeaderImageColContainer>
      </HeaderRow>
      <Badge src={newBadgeImage} />
      <HeaderLinkContainer marginTop="-30px">
        <RowColHalf>
          <LinkButton to={noiseSensorLink}>{msg('noise-sensor-title')}</LinkButton>
        </RowColHalf>
        <RowColHalf>
          <LinkButton to={peopleCounterLink}>People Counter</LinkButton>
        </RowColHalf>
        <RowColHalf>
          <LinkButton to={checkInAndOutLink}>Check In & Out</LinkButton>
        </RowColHalf>
      </HeaderLinkContainer>
      <Section title={msg('platform-measure-operations-main-title')} lang={lang} lineWidth={100}>
        <SectionSubheading>{msg('platform-measure-operations-main-desc')}</SectionSubheading>
      </Section>
      <Subsection
        padding={defaultPadding}
        title={msg('product-services-noise-sensor-title')}
        headerTitleLabel={msg('noise-sensor-title')}
        lang={lang}
        titleWidth="900px"
        lineWidth={0}
        showLine={true}
        id={SectionIds.noiseSensor.slice(1)}
      >
        <Row>
          <TextParagraph>
            {msg('product-services-noise-sensor-description')}
          </TextParagraph>
        </Row>
        <Row>
          <RowColHalf>
            <OptionImage src={noiseSensorTrendsImg} />
          </RowColHalf>
          <RowColHalf>
            <TextParagraph><b>{msg('product-services-noise-sensor-text-1-bold')}</b> {msg('product-services-noise-sensor-text-1-regular')}</TextParagraph>
            <TextParagraph><b>{msg('product-services-noise-sensor-text-2-bold')}</b> {msg('product-services-noise-sensor-text-2-regular')}</TextParagraph>
            <TextParagraph><b>{msg('product-services-noise-sensor-text-3-bold')}</b> {msg('product-services-noise-sensor-text-3-regular')}</TextParagraph>
            <TextParagraph>{msg('product-services-noise-sensor-text-4')}</TextParagraph>
          </RowColHalf>
        </Row>
      </Subsection>
      <Subsection
        padding={defaultPadding}
        title={msg('platform-measure-operations-section-1-title')} lang={lang}
        headerTitleLabel={msg('platform-measure-operations-section-1-title-label')}
        titleWidth="900px"
        lineWidth={0}
        showLine={true}
        id={SectionIds.operationsCounter.slice(1)}
      >
        <Row>
          <TextParagraph>{msg('platform-measure-operations-section-1-text')}</TextParagraph>
        </Row>
        <Row>
          <Row3Col1>
            <RowGraphicWrapper padding="0 30px 0 0">
              <OptionImage src={crowdAboveImage} />
            </RowGraphicWrapper>
          </Row3Col1>
          <Row3Col2>
            <TextParagraph>
              <BoldText>{msg('platform-measure-operations-section-1-bullet-1-heading')}</BoldText> {msg('platform-measure-operations-section-1-bullet-1-text')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-operations-section-1-bullet-2-heading')}</BoldText> {msg('platform-measure-operations-section-1-bullet-2-text')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-operations-section-1-bullet-3-heading')}</BoldText> {msg('platform-measure-operations-section-1-bullet-3-text')}
            </TextParagraph>
          </Row3Col2>
        </Row>
        <Row>
          <RowColHalf>
            <RowGraphicWrapper padding="0 15px 0 0">
              <OptionImage src={storeFrontImage} />
            </RowGraphicWrapper>
          </RowColHalf>
          <RowColHalf>
            <RowGraphicWrapper padding="0 0 0 15px">
              <PaddedOptionImage src={storeFrontAngleImage} />
            </RowGraphicWrapper>
          </RowColHalf>
        </Row>
      </Subsection>
      <Subsection
        padding={defaultPadding}
        title={msg('platform-measure-operations-section-2-title')} lang={lang}
        titleWidth="900px"
        lineWidth={0}
        showLine={true}
      >
        <Row marginTop="50px">
          <RowColHalf>
            <CounterImage src={euracamCounterImage} />
          </RowColHalf>
          <CounterBulletList align="flex-start">
            <Row>
              <IconContainer ratio={1 / 6}>
                <Icon src={cubeIcon} />
              </IconContainer>
              <RatioRowCol ratio={5 / 6}>
                <BoldText>{msg('platform-measure-operations-section-2-bullet-1-heading')}</BoldText>
                <p>
                  {msg('platform-measure-operations-section-2-bullet-1-text')}
                </p>
              </RatioRowCol>
            </Row>
            <Row>
              <IconContainer ratio={1 / 6}>
                <Icon src={chartIcon} />
              </IconContainer>
              <RatioRowCol ratio={5 / 6}>
                <BoldText>{msg('platform-measure-operations-section-2-bullet-2-heading')}</BoldText>
                <p>
                  {msg('platform-measure-operations-section-2-bullet-2-text')}
                </p>
              </RatioRowCol>
            </Row>
            <Row>
              <IconContainer ratio={1 / 6}>
                <Icon src={treeIcon} />
              </IconContainer>
              <RatioRowCol ratio={5 / 6}>
                <BoldText>{msg('platform-measure-operations-section-2-bullet-3-heading')}</BoldText>
                <p>
                  {msg('platform-measure-operations-section-2-bullet-3-text')}
                </p>
              </RatioRowCol>
            </Row>
          </CounterBulletList>
        </Row>
        <Row>
          <CustomButton onClick={(ev) => goToRequestForm(ev, lang, url)} data-ec={GACategories.Navigation} data-ea='Contact Us' data-el='Contact Us'>
            {msg('platform-measure-operations-button-label')}
          </CustomButton>
        </Row>
      </Subsection>
      <Subsection
        padding={MiddleSectionPadding}
        title={msg('platform-measure-operations-section-3-title')}
        lang={lang}
        headerTitleLabel={msg('platform-measure-operations-section-3-title-label')}
        showLine={true}
        lineWidth={0}
        id={SectionIds.checkInAndOut.slice(1)}
      >
        <Row>
          <TextParagraph>{msg('platform-measure-operations-section-3-text')}</TextParagraph>
        </Row>
        <Row>
          <Row3Col1>
            <RowGraphicWrapper>
              <OptionImage src={inOutSilo3Image} />
            </RowGraphicWrapper>
          </Row3Col1>
          <Row3Col2>
            <TextParagraph>
              <BoldText>{msg('platform-measure-operations-section-3-bullet-1-heading')}</BoldText> {msg('platform-measure-operations-section-3-bullet-1-text')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-operations-section-3-bullet-2-heading')}</BoldText> {msg('platform-measure-operations-section-3-bullet-2-text')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-operations-section-3-bullet-3-heading')}</BoldText> {msg('platform-measure-operations-section-3-bullet-3-text')}
            </TextParagraph>
          </Row3Col2>
        </Row>
        <Row>
          <TextParagraph>
            <CTAButton onClick={goToAnalyze}>{msg('platform-measure-operations-section-3-button-text')}</CTAButton>
          </TextParagraph>
        </Row>
      </Subsection>
    </Layout>
  )
})

const OperationsPage = passLanguage(OperationsPageBase)

export default OperationsPage